let subDisney = [
    {
        path: "/Suscripciones-otts_disney-activacion",
        name: "disney-activacion",
        component: () =>
            import ("@/views/suscripciones/disney/Activacion.vue")
    },
    {
        path: "/Suscripciones-otts_disney-activacion-erronea",
        name: "disney-activacion-erronea",
        component: () =>
            import ("@/views/suscripciones/disney/ActivacionErronea.vue")
    },
    {
        path: "/Suscripciones-otts_disney-activacion-exitosa",
        name: "disney-activacion-exitosa",
        component: () =>
            import ("@/views/suscripciones/disney/ActivacionExitosa.vue")
    },
    {
        path: "/Suscripciones-otts_disney-suscripcion-activa",
        name: "disney-suscripcion-activa",
        component: () =>
            import ("@/views/suscripciones/disney/SuscripcionActiva.vue")
    },
    {
        path: "/Suscripciones-otts_disney-cancelacion",
        name: "disney-cancelacion",
        component: () =>
            import ("@/views/suscripciones/disney/Cancelacion.vue")
    },
    {
        path: "/Suscripciones-otts_disney-cancelacion-exitosa",
        name: "disney-cancelacion-exitosa",
        component: () =>
            import ("@/views/suscripciones/disney/CancelacionExitosa.vue")
    },
]
export default subDisney;