import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@/assets/img/icons/icon-alert.svg';
const MY_ICONS = {
    complete: 'tel-icon-done',
    // cancel: 'tel-',
    close: 'tel-icon-main-close',
    delete: 'tel-icon-delete', // delete (e.g. v-chip close)
    // clear: 'tel-',
    success: 'tel-icon-success',
    info: 'tel-icon-info-fill',
    warning: 'tel-icon-warning',
    error: 'tel-icon-alert',
    prev: 'tel-icon-arrow-left',
    next: 'tel-icon-arrow-right',
    checkboxOn: 'tel-icon-check-on',
    checkboxOff: 'tel-icon-check-off',
    checkboxIndeterminate: 'tel-',
    // delimiter: 'tel-', // for carousel
    // sort: 'tel-',
    // expand: 'tel-',
    menu: 'tel-icon-interface-hamburger',
    // subgroup: 'tel-',
    // dropdown: 'tel-',
    radioOn: 'tel-icon-radio-on',
    radioOff: 'tel-icon-radio-off',
    edit: 'tel-icon-edit',
    // ratingEmpty: 'tel-',
    // ratingFull: 'tel-',
    // ratingHalf: 'tel-',
    // loading: 'tel-',
    // first: 'tel-',
    // last: 'tel-',
    // unfold: 'tel-',
    file: 'tel-icon-add-file',
  }
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        dark: true,
        themes: {
            dark: {
                //Colors Bot
                anchor: '#0FDBA4',
                primary: {
                    base: '#F2F8FC',
                    darken1: '#D0D0D0',
                    darken2: '#595959',
                },
                secondary: {
                    base: '#597891',
                    darken1: '#26455F',
                },
                tertiary: { base: '#0488D1' },
                accent: {
                    base: '#0071D1',
                    darken1: '#00828f',
                    lighten1: '#c71585',
                },
                text: {
                    base: '#F2F8FC',
                    darken1: '#002045',
                    darken2: '#222222',
                    lighten1: '#757575',
                },
                error: {
                    base: '#D32222',
                    lighten1: '#F7E6E6',
                    lighten2: '#FDF2F2',
                    darken1: '#9D0000',
                    darken2: '#FF6961',

                },
                info: {
                    base: '#0A84FF',
                    lighten1: '#BEDAF5',
                    lighten2: '#DDE6EE',
                    lighten3: '#F2F7F9',
                    darken1: '#0A3D7E',
                },
                success: {
                    base: '#008000',
                    lighten1: '#B5EBDB',
                    lighten2: '#DFECDF',
                    lighten3: '#EFF7EF',
                    darken1: '#00501C',
                },
                warning: {
                    base: '#EBAB07',
                    lighten1: '#F9E6B9',
                    lighten2: '#FDF6E6',
                    darken1: '#534100',
                },

                background: {
                    base: '#002044',
                    lighten1: '#315675',
                    lighten2: '#096291',
                    lighten3: '#ACBFD4',
                    lighten4: '#F2F8FC',
                    lighten5: '#FFFFFF',
                },
                //Colors Brands
                claro: { base: '#E2231A' },
                netflix: { base: '#d81f26' },
                hbo: { base: '#bb86fc' },
                disney: { base: '#0fc3da' },
            },
        },
    },
    icons: {
        values: MY_ICONS,
    },
});