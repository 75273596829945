import Vue from 'vue'
import VueRouter from 'vue-router'
import subClaros from '@/router/suscripciones/subClaros'
import subNetflix from '@/router/suscripciones/subNetflix'
import subDisney from '@/router/suscripciones/subDisney'
import subHbo from '@/router/suscripciones/subHbo'

Vue.use(VueRouter)

var routesGeneral = [{
        path: '/',
        name: 'index',
        component: () =>
            import ('../views/Index.vue')
    },
    {
        path: '/componentes',
        name: 'componentes',
        component: () =>
            import ('../views/Componentes.vue')
    },
]

var allRoutes = []
allRoutes = allRoutes.concat(subClaros, routesGeneral, subNetflix, subDisney, subHbo)

const routes = allRoutes

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router