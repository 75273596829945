let subNetflix = [
    {
        path: "/Suscripciones-otts",
        name: "inicio-otts",
        component: () =>
            import ("@/views/suscripciones/InicioOtts.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-activacion",
        name: "netflix-activacion",
        component: () =>
            import ("@/views/suscripciones/netflix/Activacion.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-activacion-erronea",
        name: "netflix-activacion-erronea",
        component: () =>
            import ("@/views/suscripciones/netflix/ActivacionErronea.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-activacion-exitosa",
        name: "netflix-activacion-exitosa",
        component: () =>
            import ("@/views/suscripciones/netflix/ActivacionExitosa.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-suscripcion-activa",
        name: "netflix-suscripcion-activa",
        component: () =>
            import ("@/views/suscripciones/netflix/SuscripcionActiva.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-cancelacion",
        name: "netflix-cancelacion",
        component: () =>
            import ("@/views/suscripciones/netflix/Cancelacion.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-cancelacion-exitosa",
        name: "netflix-cancelacion-exitosa",
        component: () =>
            import ("@/views/suscripciones/netflix/CancelacionExitosa.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-suscripcion-max-play",
        name: "netflix-suscripcion-max-play",
        component: () =>
            import ("@/views/suscripciones/netflix/SuscripcionMaxPlay.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-activacion-pantallas",
        name: "netflix-activacion-pantallas",
        component: () =>
            import ("@/views/suscripciones/netflix/ActivacionPantallas.vue")
    },
    {
        path: "/Suscripciones-otts_netflix-activacion-pantallas-exitosa",
        name: "netflix-activacion-pantallas-exitosa",
        component: () =>
            import ("@/views/suscripciones/netflix/ActivacionPantallasExitosa.vue")
    },
]
export default subNetflix;