<template>
  <v-app :class="extraClass">
  <!-- //Header para el index prototipos -->
  <div v-if="visible">
    <v-app-bar height="46px">
      <div class="d-flex justify-space-between align-center " style="width: 100%;">
        <v-img width="137px" max-width="137px" src="@/assets/img/logos/internal/telcelbot-logo.png"></v-img>
        <div>
        <Button @function="goTo(ruta)" type="secondary d-inline-block" :text="text"></Button>
        </div>
      </div>
    </v-app-bar>
  </div>
  <!-- //Header para el index prototipos -->
    <router-view></router-view>
  </v-app>
</template>

<script>
import Button from './components/Button.vue';
export default {
    name: "App",
    data() {
        return {
            extraClass: "chatbot-simulate",
            visible: true,
            ruta: '/componentes',
            text: 'Componentes'
        };
    },
    watch: {
        //Simula burbujas para los prototipos
        "$route"() {
            if (this.$route.path === "/" || this.$route.path === "/componentes") {
                this.extraClass = "chatbot-simulate";
                this.visible =true;
                this.ruta= '/componentes'
                  this.text= 'Componentes'
                if( this.$route.path === "/componentes"){
                  this.ruta= '/'
                  this.text= 'Index'
                }
            }
            else {
                this.extraClass = "chatbot-simulate chatbot-simulate-burble";
                this.visible =false;
            }
        }
    },
    components: { Button },
    methods: {
    goTo(ruta) {
      this.$router.push(ruta);
    },
  },

};
</script>

<style lang="scss">
 //Simula burbujas para los prototipos
.chatbot-simulate {
  .v-application--wrap >.container{
    max-width: 280px;
    margin: 0 auto;
    min-height: auto !important;
  }
  &.chatbot-simulate-burble{
    .v-application--wrap >.container{
     background: $background-lighten1;
    border-radius: 4px;
    margin-top: 8px;}
  }
}
</style>
