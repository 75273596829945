let subHbo = [
    {
        path: "/Suscripciones-otts_hbo-activacion",
        name: "hbo-activacion",
        component: () =>
            import ("@/views/suscripciones/hbo/Activacion.vue")
    },
    {
        path: "/Suscripciones-otts_hbo-activacion-erronea",
        name: "hbo-activacion-erronea",
        component: () =>
            import ("@/views/suscripciones/hbo/ActivacionErronea.vue")
    },
    {
        path: "/Suscripciones-otts_hbo-activacion-exitosa",
        name: "hbo-activacion-exitosa",
        component: () =>
            import ("@/views/suscripciones/hbo/ActivacionExitosa.vue")
    },
    {
        path: "/Suscripciones-otts_hbo-suscripcion-activa",
        name: "hbo-suscripcion-activa",
        component: () =>
            import ("@/views/suscripciones/hbo/SuscripcionActiva.vue")
    },
    {
        path: "/Suscripciones-otts_hbo-cancelacion",
        name: "hbo-cancelacion",
        component: () =>
            import ("@/views/suscripciones/hbo/Cancelacion.vue")
    },
    {
        path: "/Suscripciones-otts_hbo-cancelacion-exitosa",
        name: "hbo-cancelacion-exitosa",
        component: () =>
            import ("@/views/suscripciones/hbo/CancelacionExitosa.vue")
    },
    {
        path: "/Suscripciones-hbo-banner",
        name: "suscripcionesHboBanner",
        component: () =>
            import ("@/views/suscripciones/BannerHbo.vue"),
    },
]
export default subHbo;