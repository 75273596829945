<template>
  <v-btn
    @click="$emit('function')"
    :disabled="disabled"
    block
    :class="'btn--' + type"
    elevation="0"
    >{{ text }}</v-btn
  >
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      require: true,
      type: String,
    },
    text: {
      require: true,
      type: String,
    },
    disabled: {
      require: true,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
$alert-on_page-theme: (
  "primary": $primary-base $primary-base $text-darken1 $primary-darken1
    $primary-darken1 $primary-darken2,
  "secondary": $primary-base $secondary-base $primary-base $secondary-darken1
    $secondary-base $secondary-darken1,
  "tertiary": $tertiary-base $tertiary-base $primary-base $primary-darken1
    $primary-darken1 $primary-darken2,
) !default;
@each $type, $value in $alert-on_page-theme {
  .theme--dark.btn--#{$type} {
    &.v-btn.v-btn--has-bg {
      color: nth($value, 3) !important;
      background: nth($value, 2) !important;
      border: 1px solid nth($value, 1) !important;
      border-radius: 4px;
      padding: 8px 16px !important;
      font-weight: 600;
      height: auto !important;
      font-size: 1rem;
      letter-spacing: normal;
      text-transform: none;
      &.v-btn--disabled {
        color: nth($value, 6) !important;
        background: nth($value, 5) !important;
        border: 1px solid nth($value, 4) !important;
      }
    }
  }
}
</style>
