let subClaros = [{
        path: "/Suscripciones-claro",
        name: "suscripcionesClaro",
        component: () =>
            import ("@/views/suscripciones/InicioClaro.vue"),
    },
    {
        path: "/Suscripciones-claro_video-activacion",
        name: "inicios-claro",
        component: () =>
            import ("@/views/suscripciones/claro-video/Activacion.vue"),
    },
    {
        path: "/Suscripciones-claro_video-activacion_exitosa",
        name: "suscripcionesClaroVideoExito",
        component: () =>
            import ("@/views/suscripciones/claro-video/ActivacionExitosa.vue"),
    },
    {
        path: "/Suscripciones-claro_musica-activacion",
        name: "suscripcionesClaroMusica",
        component: () =>
            import ("@/views/suscripciones/claro-musica/Activacion.vue"),
    },
    {
        path: "/Suscripciones-claro_drive-activacion",
        name: "suscripcionesClaroDrive",
        component: () =>
            import ("@/views/suscripciones/claro-drive/Activacion.vue"),
    },
    {
        path: "/Suscripciones-claro_musica-banner",
        name: "suscripcionesClaroMusicaBanner",
        component: () =>
            import ("@/views/suscripciones/BannerClaroMusica.vue"),
    },
]
export default subClaros;